<template>
    <div class="section" key="applicant">
        <div class="section-title">
            <div class="author"><img src="@/assets/images/rabier.png"></div>
            <h3><span>Schritt 2</span> Wer stellt den Scheidungsantrag?</h3>
            <p>Bitte geben Sie an wer den Scheidungsantrag stellt. Leben Sie in einer gleichgeschlechtlichen Ehe geben Sie dies bitte an.</p>
        </div>
        <div class="form-container">
                  <div class="form-card">
                    <input type="radio" v-model="user.antragsteller" name="antragsteller" value="Ehemann" id="radio-3-1">
                    <label for="radio-3-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-user-male-100.svg"></span><span>Ehemann</span></label>
                  </div>
                  <div class="form-card">
                    <input type="radio" v-model="user.antragsteller" name="antragsteller" value="Ehefrau" id="radio-3-2">
                    <label for="radio-3-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-female-user-100.svg"></span><span>Ehefrau</span></label>
                  </div>
                  <div class="form-card">
                    <input type="radio" v-model="user.antragsteller" name="antragsteller" value="Ehepartner" id="radio-3-3">
                    <label for="radio-3-3" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-user-account-100.svg"></span><span>Ehepartner</span></label>
                  </div>
                </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Applicant',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>